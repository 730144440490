import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { actions } from 'src/reducers';
import { isAsapPickupTime } from 'src/utils/order/isAsapPickupTime';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPlannedPickupTime } from 'src/utils/order/isPlannedPickupTime';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { formatPickupTime } from 'src/utils/transform/formatPickupTime';

export function SelectPickupTimeButton(): React.ReactElement {
    const driverArrivesAtStoreTime = useSelector((state) => state.app.driverArrivesAtStoreTime);
    const orderType = useSelector((state) => state.app.orderType);
    const pickupTimeType = useSelector((state) => state.app.pickupTimeType);
    const pickupTime = useSelector((state) => state.app.pickupTime);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const preparationTime = useSelector((state) => state.app.restaurant?.preparationTime);
    const hideDeliveryEstimatedTimeEnabled = useSelector((state) => state.app.restaurant?.hideDeliveryEstimatedTimeEnabled);

    const openSelectPickupTimeDialog = useAction(actions.openSelectPickupTimeDialog);

    const translatePickupTime = () => {
        if (isAsapPickupTime(pickupTimeType)) {
            if (hideDeliveryEstimatedTimeEnabled) return translate('Order now');

            if (isDeliveryOrder(orderType)) {
                return translate('Estimated delivery @minutes min', { minutes: translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`) });
            }
            return translate('Ready in @minutes min').replace('@minutes', `${preparationTime}-${preparationTime + 5}`);
        }
        if (isPlannedPickupTime(pickupTimeType)) {
            return formatPickupTime(pickupTime, timeZone);
        }
        return translate('Select time for pre-ordering');
    };

    return (
        <CheckoutElement
            title={translate('Program your order')}
            content={translatePickupTime()}
            action={openSelectPickupTimeDialog}
            actionTitle={translate('Select delivery time')}
            icon={<ClockIcon color={'#C1C3C8'} />}
        />
    );
}
