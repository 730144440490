import { getAppContextApi, GetAppContextApiResponse, RestaurantChainVm, RestaurantMenuVm, RestaurantVm } from 'src/api/pidedirecto/getAppContextApi';
import { getAppContextSignedInApi, GetAppContextSignedInApiResponse } from 'src/api/pidedirecto/getAppContextSignedInApi';
import { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { history } from 'src/config/history';
import { addRestaurantGoogleAnalytics } from 'src/facade/googleAnalytics/addRestaurantGoogleAnalytics';
import { setRestaurantColorsInLocalStorage } from 'src/localStorage/setRestaurantColorsInLocalStorage';
import { useSetPromoCodes } from 'src/modules/promoCode/useSetPromoCodes';
import { actions } from 'src/reducers';
import { PromotionVm } from 'src/types/PromotionVm';
import { isSignedIn } from 'src/utils/cognito/isSignedIn';
import { isInvoicePage } from 'src/utils/page/isInvoicePage';
import { isPaymentLinkPage } from 'src/utils/page/isPaymentLinkPage';
import { isSurveyPage } from 'src/utils/page/isSurveyPage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';
import { unzip } from 'src/utils/unzip';

export function useGetAppContext(): { getAppContext: GetAppContextFunction; getAppContextSignedIn: GetAppContextSignedInFunction } {
    const setPromoCodes = useSetPromoCodes();

    const currentUrlPathname = useSelector((state) => state.app.urlPathname);
    const page = useSelector((state) => state.app.page);

    const setCustomerId = useAction(actions.setCustomerId);
    const setNumberOfOrders = useAction(actions.setNumberOfOrders);
    const setMobileNumber = useAction(actions.setMobileNumber);
    const setEmail = useAction(actions.setEmail);
    const setFirstName = useAction(actions.setFirstName);
    const setLastName = useAction(actions.setLastName);
    const setAddresses = useAction(actions.setAddresses);
    const setCards = useAction(actions.setCards);
    const setCreditsAndGifts = useAction(actions.setCreditsAndGifts);
    const setContentNotFound = useAction(actions.setContentNotFound);
    const setLoadingAppContext = useAction(actions.setLoadingAppContext);
    const setRedirectUrl = useAction(actions.setRedirectUrl);
    const openRestaurantChain = useAction(actions.openRestaurantChain);
    const setQuickActionMessage = useAction(actions.setQuickActionMessage);
    const setSettings = useAction(actions.setSettings);
    const setHasPassword = useAction(actions.setHasPassword);
    const refreshRestaurant = useAction(actions.refreshRestaurant);
    const openRestaurant = useAction(actions.openRestaurant);
    const openCreateCustomerAccountDialog = useAction(actions.openCreateCustomerAccountDialog);
    const setPromotions = useAction(actions.setPromotions);

    const handleGetAppContext: GetAppContextFunction = async ({ urlSubdomain, urlPathname }) => {
        if (!urlSubdomain) return { ok: false };

        setLoadingAppContext(true);
        if (await isSignedIn()) {
            return getAppContextSignedIn({ urlSubdomain, urlPathname });
        }

        return getAppContext({ urlSubdomain, urlPathname });
    };

    const getAppContext = async ({ urlSubdomain, urlPathname }: { urlSubdomain: string; urlPathname: string | undefined }) => {
        const response = await getAppContextApi({ urlSubdomain, urlPathname });
        if (!response.ok) {
            return handleFailed();
        }
        const context: GetAppContextApiResponse = unzip(response.data);

        if (context.restaurantNotFound) {
            handleRestaurantNotFound();
            return handleSucceeded();
        }

        if (context.redirectUrl) {
            handleRedirectUrl(context.redirectUrl);
            return handleSucceeded();
        }

        setPwa(context.pwa);

        const currentRestaurant = getCurrentRestaurant(context.restaurants, context.urlPathname);

        if (shouldRedirectToRestaurantChainPage(currentRestaurant)) {
            context.restaurantChain ? redirectToRestaurantChainPage({ restaurants: context.restaurants ?? [], restaurantChain: context.restaurantChain }) : handleRestaurantNotFound();
            return handleSucceeded();
        }

        setQuickActionMessage(context.quickActionMessage);

        setSettings(context.settings);

        if (context.urlPathname === currentUrlPathname) {
            refreshCurrentRestaurant({ restaurants: context.restaurants, urlPathname, restaurantMenu: context.restaurantMenu });
            return handleSucceeded(context);
        }

        setRestaurantInfo({
            restaurants: context.restaurants,
            promotions: context.promotions,
            urlPathname: context.urlPathname,
            restaurantMenu: context.restaurantMenu,
            promoCodes: context.promoCodes,
        });

        setLoadingAppContext(false);
        return handleSucceeded(context);
    };

    const getAppContextSignedIn = async ({
        urlSubdomain,
        urlPathname,
        preventAskForUserInfo,
    }: {
        urlSubdomain: string | undefined;
        urlPathname: string | undefined;
        preventAskForUserInfo?: boolean;
    }) => {
        if (!urlSubdomain) return handleFailed();
        const response = await getAppContextSignedInApi({ urlSubdomain, urlPathname });
        if (!response.ok) {
            return handleFailed();
        }
        const context: GetAppContextSignedInApiResponse = unzip(response.data);

        if (context.restaurantNotFound) {
            handleRestaurantNotFound();
            return handleSucceeded();
        }

        if (context.redirectUrl) {
            handleRedirectUrl(context.redirectUrl);
            return handleSucceeded();
        }

        setCustomerInfo(context, preventAskForUserInfo);
        setPwa(context.pwa);

        const currentRestaurant = getCurrentRestaurant(context.restaurants, context.urlPathname);
        if (shouldRedirectToRestaurantChainPage(currentRestaurant)) {
            context.restaurantChain ? redirectToRestaurantChainPage({ restaurants: context.restaurants ?? [], restaurantChain: context.restaurantChain }) : handleRestaurantNotFound();
            return handleSucceeded();
        }

        setQuickActionMessage(context.quickActionMessage);

        if (context.urlPathname === currentUrlPathname) {
            refreshCurrentRestaurant({ restaurants: context.restaurants, urlPathname, restaurantMenu: context.restaurantMenu });
            return handleSucceeded(context);
        }

        setRestaurantInfo({
            restaurants: context.restaurants,
            urlPathname: context.urlPathname,
            restaurantMenu: context.restaurantMenu,
            promotions: context.promotions,
            promoCodes: context.promoCodes,
        });

        return handleSucceeded(context);
    };

    const handleFailed = () => {
        setLoadingAppContext(false);
        return { ok: false };
    };

    const handleSucceeded = (data?: GetAppContextApiResponse | GetAppContextSignedInApiResponse) => {
        setLoadingAppContext(false);
        return { ok: true, data };
    };

    const handleRestaurantNotFound = () => {
        setContentNotFound(true);
        setLoadingAppContext(false);
    };

    const handleRedirectUrl = (redirectUrl: string) => {
        setRedirectUrl(redirectUrl);
        setLoadingAppContext(false);
    };

    const setCustomerInfo = (data: GetAppContextSignedInApiResponse, preventAskForUserInfo?: boolean) => {
        if ((!data.email || !data.firstName || !data.lastName || !data.mobileNumber) && !preventAskForUserInfo) {
            openCreateCustomerAccountDialog({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber,
            });
        }
        setCustomerId(data.customerId);
        setNumberOfOrders(data.numberOfOrders);
        setMobileNumber(data.mobileNumber);
        setHasPassword(data.hasPassword);
        setEmail(data.email);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setAddresses(data.addresses);
        setCards(data.cards);
        setCreditsAndGifts({
            credits: data.credits,
            gifts: data?.gifts,
        });
    };

    const setPwa = (
        pwa:
            | undefined
            | {
                  appicon: string;
                  theme_color: string;
              },
    ) => {
        if (!pwa) return;

        if (window.localStorage) window.localStorage.setItem('_theme_color', pwa.theme_color);
        (document.getElementById('_theme-color') as any).content = pwa.theme_color;
    };

    const getCurrentRestaurant = (restaurants: undefined | Array<RestaurantVm>, urlPathname: undefined | string) =>
        restaurants?.find((restaurant: RestaurantVm) => restaurant.urlPathname === urlPathname);

    const setRestaurantInfo = ({
        restaurants,
        urlPathname,
        restaurantMenu,
        promotions,
        promoCodes,
    }: {
        restaurants?: Array<RestaurantVm>;
        urlPathname?: string;
        restaurantMenu?: RestaurantMenuVm;
        promotions?: Array<PromotionVm>;
        promoCodes?: Array<PromoCodeVm>;
    }) => {
        const currentRestaurant = getCurrentRestaurant(restaurants, urlPathname);

        if (shouldRedirectToRestaurantMenuPage(currentRestaurant)) {
            redirectToRestaurantMenuPage(urlPathname);
        }

        openRestaurant({
            restaurants: restaurants,
            urlPathname: urlPathname,
            restaurantMenu: restaurantMenu,
        });
        setPromotions(promotions ?? []);
        setPromoCodes(promoCodes ?? []);

        loadRestaurantColors(currentRestaurant);
        if (currentRestaurant?.googleAnalyticsId) addRestaurantGoogleAnalytics(currentRestaurant.googleAnalyticsId);
    };

    const shouldRedirectToRestaurantChainPage = (restaurant?: RestaurantVm) => {
        const paymentLinkRegexp = new RegExp('^\\/\\w+\\/pay\\/[a-f\\d]{8}-(?:[a-f\\d]{4}-){3}[a-f\\d]{12}$', 'i');
        const invoiceLinkRegexp = new RegExp('^\\/\\w+\\/factura$', 'i');
        const isValidPaymentLink = paymentLinkRegexp.test(window.location.pathname);
        const isValidInvoiceLink = invoiceLinkRegexp.test(window.location.pathname);
        return !restaurant?.webOrdersEnabled && !(isValidPaymentLink && restaurant?.paymentLinksEnabled) && !(isValidInvoiceLink && restaurant);
    };

    const redirectToRestaurantChainPage = ({ restaurants, restaurantChain }: { restaurants: Array<RestaurantVm>; restaurantChain: RestaurantChainVm | undefined }) => {
        const restaurantsWithWebOrdersEnabled = restaurants.filter((restaurant) => restaurant.webOrdersEnabled);
        history.replace('/');
        openRestaurantChain({
            restaurantChain: restaurantChain,
            restaurants: restaurantsWithWebOrdersEnabled,
        });
        setLoadingAppContext(false);
    };

    const refreshCurrentRestaurant = ({
        restaurants,
        urlPathname,
        restaurantMenu,
    }: {
        restaurants: Array<RestaurantVm> | undefined;
        urlPathname: string | undefined;
        restaurantMenu: RestaurantMenuVm | undefined;
    }) => {
        refreshRestaurant({
            restaurants: restaurants,
            urlPathname: urlPathname,
            restaurantMenu: restaurantMenu,
        });
        setLoadingAppContext(false);
    };

    const shouldRedirectToRestaurantMenuPage = (restaurant?: RestaurantVm) => {
        const isRestaurantMenuPage = !isPaymentLinkPage(page) && !isInvoicePage(page) && !isSurveyPage(page);
        return isRestaurantMenuPage || (isInvoicePage(page) && !canRestaurantInvoice(restaurant));
    };

    const redirectToRestaurantMenuPage = (urlPathname?: string) => {
        if (!urlPathname) return;
        history.replace(`/${urlPathname}`);
    };

    const loadRestaurantColors = (restaurant?: RestaurantVm) => {
        if (!restaurant) return;
        if (window.localStorage && restaurant.restaurantColors) setRestaurantColorsInLocalStorage(restaurant.restaurantColors);
        if (window.localStorage && !restaurant.restaurantColors) setRestaurantColorsInLocalStorage(undefined);
    };

    return { getAppContext: handleGetAppContext, getAppContextSignedIn };
}

type GetAppContextFunction = (arg1: { urlSubdomain: string | undefined; urlPathname: string | undefined }) => Promise<{
    ok: boolean;
    data?: GetAppContextSignedInApiResponse | GetAppContextApiResponse;
}>;

type GetAppContextSignedInFunction = (arg1: { urlSubdomain: string | undefined; urlPathname: string; preventAskForUserInfo?: boolean }) => Promise<{
    ok: boolean;
    data?: GetAppContextSignedInApiResponse | GetAppContextApiResponse;
}>;
