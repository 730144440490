import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { SummaryRow } from 'src/components/SummaryRow';
import { Text } from 'src/components/Text';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { PaymentRejectReason, PaymentRejectReasons } from 'src/constants/PaymentRejectReason';
import { PaymentStatuses } from 'src/constants/PaymentStatus';
import { RefundMethods } from 'src/constants/RefundMethod';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { AppTheme } from 'src/styles/AppTheme';
import { useSelector } from 'src/utils/react/useSelector';
import { negateNumberString } from 'src/utils/string/negateNumberString';

export function OrderSummary(): React.ReactElement | null {
    const theme = useTheme();
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const order = useSelector((state) => state.app.order);
    const restaurant = useSelector((state) => state.app.restaurant);
    const invoiceRestaurantDeliveryCost = useSelector((state) => state.app.restaurant?.invoiceRestaurantDeliveryCost);
    const deliveryCost = order.customerDeliveryCost ?? order.deliveryCost;

    useEffect(() => {
        if (!restaurant.facebookPixelId) return;
        ReactPixel.init(restaurant.facebookPixelId);

        ReactPixel.track('Purchase', {
            content_type: 'product',
            contents: order.orderItems.map((orderItem) => {
                return {
                    id: orderItem.menuItemId,
                    quantity: orderItem.quantity,
                };
            }),
            value: order.total,
            currency: restaurant.country,
        });
    }, []);

    useEffect(() => {
        if (!restaurant.googleAnalyticsId) return;
        const dataLayer = {
            event: 'Purchase',
            products: order.orderItems.map((orderItem) => {
                return {
                    id: orderItem.menuItemId,
                    name: orderItem.name,
                    price: orderItem.unitPrice,
                    quantity: orderItem.quantity,
                };
            }),
            total: order.total,
        } as const;
        const tagManagerData = {
            gtmId: restaurant.googleAnalyticsId,
            dataLayer,
        } as const;
        TagManager.initialize(tagManagerData);
    }, []);

    if (!order) return null;

    return (
        <div className={classes.container}>
            {order.subtotal !== order.total && <SummaryRow text={translate('Subtotal')} value={formatAsCurrencyNumber(order.subtotal)} />}
            {order.productDiscount && <SummaryRow text={translate('Product Discount')} value={formatAsCurrencyNumber(negateNumberString(order.productDiscount))} />}
            {deliveryCost && !invoiceRestaurantDeliveryCost && <SummaryRow text={translate('Delivery')} value={formatAsCurrencyNumber(deliveryCost)} />}
            {order.tax && <SummaryRow text={translate('Tax')} value={formatAsCurrencyNumber(order.tax)} />}
            {order.deliveryCashHandlingFee && <SummaryRow text={translate('Cash fee')} value={formatAsCurrencyNumber(order.deliveryCashHandlingFee)} />}
            {order.serviceFee && <SummaryRow text={translate('Service fee')} value={formatAsCurrencyNumber(order.serviceFee)} />}
            {order.promoCodeDiscount && <SummaryRow text={translate('Promo Code')} value={formatAsCurrencyNumber(negateNumberString(order.promoCodeDiscount))} />}
            {order.companyCredits && (
                <SummaryRow
                    text={translate('Paid by @company', { company: order.companyName })}
                    value={formatAsCurrencyNumber(negateNumberString(order.companyCredits))}
                    className={{ color: theme.palette.primary }}
                />
            )}
            {order.letsEatCredits && <SummaryRow text={translate('Credits')} value={formatAsCurrencyNumber(negateNumberString(order.letsEatCredits))} />}
            <div className={classes.totalRow}>
                {order ? (
                    <Text className={classes.paymentStatus}>
                        {translate('Total')} ({translatePaymentStatus(order)})
                    </Text>
                ) : (
                    <div></div>
                )}
                <Text className={classes.total}>{formatAsCurrencyNumber(order.total)}</Text>
            </div>
        </div>
    );
}

function translatePaymentStatus(order?: OrderVm): string {
    if (!order) {
        return '';
    }
    if (order.paymentMethod === PaymentMethods.CREDIT_CARD) {
        if (order.total === '0' && order.usedCredits) {
            if (order.paymentStatus === PaymentStatuses.REFUNDED) {
                return translate('Refunded credits');
            } else {
                return '';
            }
        }
        if (order.paymentStatus === PaymentStatuses.PAID) {
            return translate('Paid with card');
        }
        if (order.paymentStatus === PaymentStatuses.PAYED) {
            // TODO: Remove when deprecated and PAID is used in server instead
            return translate('Paid with card');
        }
        if (order.paymentStatus === PaymentStatuses.REFUNDED) {
            if (order.refundMethod === RefundMethods.CREDIT_CARD) {
                return translate('Paid with card, refunded to card');
            }
            if (order.refundMethod === RefundMethods.CREDITS) {
                return translate('Paid with card, refunded as credits');
            }
        }
        if (order.paymentStatus === PaymentStatuses.FAILED) {
            return translatePaymentRejectReason(order.paymentRejectReason);
        }
    } else if (order.paymentMethod === PaymentMethods.PAYROLL) {
        if (order.total === '0' && order.usedCredits) {
            if (order.paymentStatus === PaymentStatuses.REFUNDED) {
                return translate('Refunded credits');
            } else {
                return '';
            }
        }
        if (order.paymentStatus === PaymentStatuses.PAID) {
            return translate('Paid with payroll');
        }
        if (order.paymentStatus === PaymentStatuses.PAYED) {
            // TODO: Remove when deprecated and PAID is used in server instead
            return translate('Paid with payroll');
        }
        if (order.paymentStatus === PaymentStatuses.REFUNDED) {
            return translate('Paid with payroll, refunded');
        }
    } else if (order.paymentMethod === PaymentMethods.CASH) {
        if (order.paymentStatus === PaymentStatuses.UNPAID || !order.paymentStatus) {
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paying at delivery');
            }
            return translate('Paying in store');
        }
        if (order.paymentStatus === PaymentStatuses.PAID) {
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paid at delivery');
            }
            return translate('Paid in store');
        }
        if (order.paymentStatus === PaymentStatuses.PAYED) {
            // TODO: Remove when deprecated and PAID is used in server instead
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paid at delivery');
            }
            return translate('Paid in store');
        }
    } else if (order.paymentMethod === PaymentMethods.CARD_ON_DELIVERY) {
        if (order.paymentStatus === PaymentStatuses.UNPAID || !order.paymentStatus) {
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paying with card at delivery');
            }
            return translate('Paid with card at delivery');
        }
        if (order.paymentStatus === PaymentStatuses.PAID) {
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paid with card at delivery');
            }
            return translate('Paid with card at delivery');
        }
        if (order.paymentStatus === PaymentStatuses.PAYED) {
            if (order.orderType === OrderTypes.PICKUP_STATION_ORDER || order.orderType === OrderTypes.DELIVERY_ORDER) {
                return translate('Paid with card at delivery');
            }
            return translate('Paid with card at delivery');
        }
    }
    return '';
}

function translatePaymentRejectReason(paymentRejectReason?: PaymentRejectReason) {
    if (!paymentRejectReason) {
        return '';
    }
    switch (paymentRejectReason) {
        case PaymentRejectReasons.INSUFFICIENT_FUNDS: {
            return translate('Card has insufficient funds');
        }
        case PaymentRejectReasons.COMMUNICATION_ERROR:
        case PaymentRejectReasons.RESTRICTED_CARD:
        case PaymentRejectReasons.INVALID_SECURITY_CODE:
        case PaymentRejectReasons.UNCATEGORIZED:
        case PaymentRejectReasons.UNKNOWN:
        default: {
            return translate('Card payment not accepted');
        }
    }
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    totalRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 12,
    },
    paymentStatus: {
        fontSize: 14,
        color: '#4F586E',
        fontFamily: AppTheme.typography.bold,
    },
    total: {
        fontSize: 14,
        color: '#4F586E',
        fontFamily: AppTheme.typography.bold,
    },
}));
