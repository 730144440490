/* eslint-disable react-hooks/rules-of-hooks */
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import type { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { Country } from 'src/constants/Country';
import { GiftEmitter, GiftEmitters } from 'src/constants/GiftEmitter';
import { MomentFormats } from 'src/constants/MomentFormat';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import type { TimeZone } from 'src/constants/TimeZone';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { DeliveryEstimateVm } from 'src/types/DeliveryEstimateVm';
import type { GiftVm } from 'src/types/GiftVm';
import { MenuItemId, PromotionId, type GiftId, type RestaurantId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';
import { emptyArrayToUndefined } from 'src/utils/array/emptyArrayToUndefined';
import { isMexico } from 'src/utils/country/isMexico';
import { giftsByPaymentOrder } from 'src/utils/gift/compare/giftsByPaymentOrder';
import { validGiftsForOrderTypeAndCartItems } from 'src/utils/gift/filter/validGiftsForOrderTypeAndCartItems';
import { calculateOrderItemProductDiscount } from 'src/utils/order/calculateOrderItemProductDiscount';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { calculateBuyOneGetOnePromotionDiscount } from 'src/utils/payment/promotion/calculateBuyOneGetOnePromotionDiscount';
import { isCardOnDeliveryPayment } from 'src/utils/paymentMethod/isCardOnDeliveryPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { calculatePromoCodeDiscount } from 'src/utils/promoCode/calculatePromoCodeDiscount';
import { isBuyOneGetOnePromotion } from 'src/utils/promotion/isBuyOneGetOnePromotion';
import { requireValue } from 'src/utils/require/requireValue';
import { ascendingBy } from 'src/utils/sort/ascendingBy';
import { zeroStringToUndefined } from 'src/utils/transform/zeroStringToUndefined';

export function createPaymentDistribution(request: Request): Payment {
    const payment = createEmptyPayment(request);
    calculateSubtotal(payment, request);
    calculateProductDiscount(payment, request);
    calculatePromotionsDiscount(payment, request);
    calculateDeliveryCost(payment, request);
    calculateTax(payment, request);
    calculateRestaurantServiceFee(payment, request);
    usePromoCode(payment, request);
    if (request.paymentMethod && !isCashPayment(request.paymentMethod) && !isCardOnDeliveryPayment(request.paymentMethod)) {
        useCompanyGifts(payment, request);
        if (request.useLetsEatCredits) {
            useNonCompanyGifts(payment, request);
        }
    }
    cleanPayment(payment);
    return payment;
}

function createEmptyPayment(request: Request): Payment {
    const { orderType, orderItems, restaurantTimeZone } = request;
    const payment: Payment = {
        ...createEmptyPaymentData(),
        orderItemPickupTimes: [],
    };
    if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
        [...orderItems].sort(ascendingBy('pickupTime')).forEach((orderItem) => {
            const orderItemPickupTime = moment.tz(orderItem.pickupTime, restaurantTimeZone).format(MomentFormats.ISO_DATE_TIME) as PickupTime;
            if (payment[orderItemPickupTime]) return;
            payment[orderItemPickupTime] = createEmptyPaymentData();
            payment.orderItemPickupTimes.push(orderItemPickupTime);
        });
    }
    return payment;
}

function createEmptyPaymentData(): Payment {
    return {
        subtotal: '0',
        subtotalAfterDiscount: '0',
        usedGifts: [],
        usedPromotions: [],
        orderItemPickupTimes: [],
        total: '0',
    };
}

function calculateSubtotal(payment: Payment, request: Request): void {
    const { orderType, orderItems, restaurantTimeZone } = request;
    orderItems.forEach((orderItem) => {
        const orderItemSubtotal = calculateOrderItemSubtotalPrice(orderItem);
        payment.subtotal = BigNumber(payment.subtotal).plus(orderItemSubtotal).toString();
        payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount).plus(orderItemSubtotal).toString();
        payment.total = payment.subtotal;

        if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
            const orderItemPickupTime = moment.tz(orderItem.pickupTime, restaurantTimeZone).format(MomentFormats.ISO_DATE_TIME) as PickupTime;
            payment[orderItemPickupTime].subtotal = BigNumber(payment[orderItemPickupTime].subtotal).plus(orderItemSubtotal).toString();
            payment[orderItemPickupTime].total = payment[orderItemPickupTime].subtotal;
        }
    });
}

function calculateProductDiscount(payment: Payment, request: Request): void {
    const { orderType, orderItems, restaurantTimeZone } = request;
    orderItems.forEach((orderItem) => {
        const orderItemProductDiscount = calculateOrderItemProductDiscount(orderItem);
        payment.productDiscount = BigNumber(payment.productDiscount ?? 0)
            .plus(orderItemProductDiscount)
            .toString();
        payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount)
            .minus(orderItemProductDiscount ?? 0)
            .toString();
        payment.total = BigNumber(payment.total)
            .minus(orderItemProductDiscount ?? 0)
            .toString();
        if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
            const orderItemPickupTime = moment.tz(orderItem.pickupTime, restaurantTimeZone).format(MomentFormats.ISO_DATE_TIME) as PickupTime;
            payment[orderItemPickupTime].productDiscount = BigNumber(payment[orderItemPickupTime].productDiscount ?? 0)
                .plus(orderItemProductDiscount)
                .toString();
            payment[orderItemPickupTime].total = BigNumber(payment[orderItemPickupTime].total)
                .minus(orderItemProductDiscount ?? 0)
                .toString();
        }
    });
}

function calculatePromotionsDiscount(payment: Payment, request: Request): void {
    if (!request.promotions?.length) return;

    const validPromotions = request.promotions.filter((promotion) => promotion.orderTypes.includes(request.orderType!));

    for (const promotion of validPromotions) {
        if (isBuyOneGetOnePromotion(promotion.promotionType)) calculateBuyOneGetOnePromotionDiscount(payment, request, promotion);
    }
}

function usePromoCode(payment: Payment, request: Request): void {
    const { promoCode, orderType, paymentMethod, numberOfOrders } = request;
    if (!promoCode) return;

    if (orderType === OrderTypes.PICKUP_STATION_ORDER) {
        const mostExpensivePickupTime = largestTotalPickupTime(payment);
        payment.promoCodeSubtotal = payment[mostExpensivePickupTime].total;
        const promoCodeDiscount = calculatePromoCodeDiscount(promoCode, orderType, paymentMethod, numberOfOrders, payment[mostExpensivePickupTime].total, undefined, request.orderItems);
        if (promoCode.rewardType === RewardTypes.CREDITS) {
            payment.promoCodeCredits = promoCodeDiscount;
            payment[mostExpensivePickupTime].promoCodeCredits = promoCodeDiscount;
        } else {
            payment.promoCodeDiscount = promoCodeDiscount;
            payment[mostExpensivePickupTime].promoCodeDiscount = promoCodeDiscount;
            payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount)
                .minus(payment.promoCodeDiscount ?? 0)
                .toString();
            payment.total = BigNumber(payment.total)
                .minus(payment.promoCodeDiscount ?? 0)
                .toString();
            payment[mostExpensivePickupTime].total = BigNumber(payment[mostExpensivePickupTime].total)
                .minus(payment[mostExpensivePickupTime].promoCodeDiscount ?? 0)
                .toString();
        }
        payment.promoCode = promoCode.code;
        payment[mostExpensivePickupTime].promoCode = promoCode.code;
    } else {
        payment.promoCodeSubtotal = payment.total;
        const promoCodeDiscount = calculatePromoCodeDiscount(promoCode, orderType, paymentMethod, numberOfOrders, payment.total, payment.customerDeliveryCost, request.orderItems);
        if (promoCode.rewardType === RewardTypes.CREDITS) {
            payment.promoCodeCredits = promoCodeDiscount;
        } else {
            payment.promoCodeDiscount = promoCodeDiscount;
            if (!promoCode.freeDelivery) {
                payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount)
                    .minus(payment.promoCodeDiscount ?? 0)
                    .toString();
            }
            payment.total = BigNumber(payment.total)
                .minus(payment.promoCodeDiscount ?? 0)
                .toString();
        }
        payment.promoCode = promoCode.code;
    }
}

function calculateDeliveryCost(payment: Payment, request: Request): void {
    const { orderType, roomServiceFixedDeliveryCost } = request;
    if (isRoomServiceOrder(orderType) && roomServiceFixedDeliveryCost) {
        payment.deliveryCost = roomServiceFixedDeliveryCost;
        payment.customerDeliveryCost = roomServiceFixedDeliveryCost;
        payment.total = BigNumber(payment.total)
            .plus(payment.customerDeliveryCost ?? 0)
            .toString();
    }
    if (orderType === OrderTypes.DELIVERY_ORDER) {
        const deliveryEstimate = request.deliveryEstimate;
        payment.deliveryCost = deliveryEstimate?.deliveryCost;
        payment.customerDeliveryCost = deliveryEstimate?.customerDeliveryCost;
        payment.restaurantDeliveryCost = deliveryEstimate?.restaurantDeliveryCost;
        payment.total = BigNumber(payment.total)
            .plus(payment.customerDeliveryCost ?? 0)
            .plus(request.driverTip ?? 0)
            .toString();
    }
}

function calculateDeliveryCashHandlingFee(payment: Payment, request: Request): void {
    if (!isCashPayment(request.paymentMethod) || !isMexico(request.country) || !isDeliveryOrder(request.orderType) || request.externalDelivery) return;

    const deliveryCashHandlingFee = BigNumber(payment.subtotalAfterDiscount).multipliedBy(0.025).toString();

    payment.total = BigNumber(payment.total).plus(deliveryCashHandlingFee).toString();
    payment.deliveryCashHandlingFee = deliveryCashHandlingFee;
}

function calculateTax(payment: Payment, request: Request): void {
    const { addTaxToTotalEnabled, taxRate } = request;
    if (addTaxToTotalEnabled) {
        payment.tax = BigNumber(payment.total)
            .multipliedBy(taxRate ?? 0)
            .dividedBy(100)
            .decimalPlaces(2)
            .toString();
        payment.taxRate = taxRate;
        payment.total = BigNumber(payment.total)
            .plus(payment.tax ?? 0)
            .toString();
    }
}

function calculateRestaurantServiceFee(payment: Payment, request: Request): void {
    if (!request.restaurantServiceFeeEnabled || !request.restaurantServiceFeeRate) return;
    const restaurantServiceFee = BigNumber(payment.total).multipliedBy(request.restaurantServiceFeeRate).toString();
    payment.serviceFee = restaurantServiceFee;
    payment.serviceFeeRate = request.restaurantServiceFeeRate;
    payment.restaurantServiceFee = restaurantServiceFee;
    payment.restaurantServiceFeeRate = request.restaurantServiceFeeRate;
    payment.total = BigNumber(payment.total).plus(restaurantServiceFee).toString();
}

function useCompanyGifts(payment: Payment, request: Request): void {
    const { gifts } = request;
    if (!gifts) return;

    useGifts(
        payment,
        request,
        gifts.filter((gift) => gift.giftEmitter === GiftEmitters.COMPANY),
    );
}

function useNonCompanyGifts(payment: Payment, request: Request): void {
    const { gifts } = request;
    if (!gifts) return;

    useGifts(
        payment,
        request,
        gifts.filter((gift) => gift.giftEmitter !== GiftEmitters.COMPANY),
    );
}

function useGifts(payment: Payment, request: Request, gifts?: Array<GiftVm>): void {
    const { orderType, pickupTime, orderItems, restaurantTimeZone } = request;
    if (!gifts) return;

    gifts
        .filter(validGiftsForOrderTypeAndCartItems(orderType, pickupTime, orderItems, restaurantTimeZone))
        .filter((gift) => isGiftUsableInRestaurant(gift, request.restaurantId))
        .sort(giftsByPaymentOrder)
        .forEach((gift) => {
            if (BigNumber(payment.total).isZero()) return;
            if (BigNumber(payment.usedGiftCredits ?? 0).isEqualTo(payment.total)) return;

            const availableCredits = BigNumber(gift.credits)
                .minus(gift.usedCredits ?? 0)
                .toString();

            const totalLeftToPay = BigNumber(payment.total).minus(payment.usedCredits ?? 0);
            let creditsToUse = availableCredits;
            if (BigNumber(availableCredits).isGreaterThanOrEqualTo(totalLeftToPay)) {
                creditsToUse = totalLeftToPay.toString();
            }

            useGiftCredits(requireValue(payment.usedGifts), gift, creditsToUse);
            payment.usedGiftCredits = BigNumber(payment.usedGiftCredits ?? 0)
                .plus(creditsToUse)
                .toString();
            payment.usedLetsEatCredits = BigNumber(payment.usedLetsEatCredits ?? 0)
                .plus(creditsToUse)
                .toString();
            payment.usedCredits = BigNumber(payment.usedCredits ?? 0)
                .plus(creditsToUse)
                .toString();
        });
}

function cleanPayment(payment: Payment): void {
    payment.productDiscount = zeroStringToUndefined(payment.productDiscount);
    payment.promoCodeSubtotal = zeroStringToUndefined(payment.promoCodeSubtotal);
    payment.promoCodeDiscount = zeroStringToUndefined(payment.promoCodeDiscount);
    payment.promoCodeCredits = zeroStringToUndefined(payment.promoCodeCredits);
    payment.promoCode = zeroStringToUndefined(payment.promoCode);
    payment.usedGifts = emptyArrayToUndefined(payment.usedGifts);
    payment.usedGiftCredits = zeroStringToUndefined(payment.usedGiftCredits);
    payment.usedNonGiftCredits = zeroStringToUndefined(payment.usedNonGiftCredits);
    payment.usedLetsEatCredits = zeroStringToUndefined(payment.usedLetsEatCredits);
    payment.usedCompanyCredits = zeroStringToUndefined(payment.usedCompanyCredits);
    payment.usedCredits = zeroStringToUndefined(payment.usedCredits);
    payment.orderItemPickupTimes.forEach((pickupTime) => {
        payment[pickupTime].productDiscount = zeroStringToUndefined(payment[pickupTime].productDiscount);
        payment[pickupTime].promoCodeDiscount = zeroStringToUndefined(payment[pickupTime].promoCodeDiscount);
        payment[pickupTime].promoCodeCredits = zeroStringToUndefined(payment[pickupTime].promoCodeCredits);
        payment[pickupTime].promoCode = zeroStringToUndefined(payment[pickupTime].promoCode);
        payment[pickupTime].usedGifts = emptyArrayToUndefined(payment[pickupTime].usedGifts);
        payment[pickupTime].usedGiftCredits = zeroStringToUndefined(payment[pickupTime].usedGiftCredits);
        payment[pickupTime].usedNonGiftCredits = zeroStringToUndefined(payment[pickupTime].usedNonGiftCredits);
        payment[pickupTime].usedLetsEatCredits = zeroStringToUndefined(payment[pickupTime].usedLetsEatCredits);
        payment[pickupTime].usedCompanyCredits = zeroStringToUndefined(payment[pickupTime].usedCompanyCredits);
        payment[pickupTime].usedCredits = zeroStringToUndefined(payment[pickupTime].usedCredits);
    });
}

function largestTotalPickupTime(payment: Payment): PickupTime {
    let largestTotalPickupTime = payment.orderItemPickupTimes[0];
    let largestTotal = BigNumber(payment[payment.orderItemPickupTimes[0]].total);
    payment.orderItemPickupTimes.forEach((pickupTime) => {
        if (BigNumber(payment[pickupTime].total).isGreaterThan(largestTotal)) {
            largestTotalPickupTime = pickupTime;
            largestTotal = BigNumber(payment[pickupTime].total);
        }
    });
    return largestTotalPickupTime;
}

function useGiftCredits(usedGifts: Array<UsedGift>, gift: GiftVm, amount: string): void {
    const usedGift = usedGifts.find((usedGift) => usedGift.giftId === gift.giftId);
    if (usedGift) {
        usedGift.usedCredits = BigNumber(usedGift.usedCredits).plus(amount).toString();
        return;
    }
    usedGifts.push({
        giftId: gift.giftId,
        giftEmitter: gift.giftEmitter,
        usedCredits: amount,
        companyName: gift.companyName,
        promoCode: gift.promoCode,
    });
}

function isGiftUsableInRestaurant(gift: GiftVm, restaurantId: RestaurantId): boolean {
    if (!gift.restaurantIds) return true;
    return gift.restaurantIds.includes(restaurantId);
}

export type Request = {
    restaurantId: RestaurantId;
    country: Country;
    credits?: string;
    numberOfOrders: number;
    gifts?: Array<GiftVm>;
    promoCode?: PromoCodeVm;
    deliveryEstimate?: DeliveryEstimateVm;
    orderType?: OrderType;
    paymentMethod?: PaymentMethod;
    pickupTime?: Date | string;
    roomServiceFixedDeliveryCost?: string;
    orderItems: Array<CartItemVm>;
    promotions?: Array<PromotionVm>;
    restaurantTimeZone: TimeZone;
    useLetsEatCredits?: boolean;
    usedCredits?: string;
    driverTip?: string;
    addTaxToTotalEnabled?: boolean;
    restaurantServiceFeeEnabled?: boolean;
    restaurantServiceFeeRate?: number;
    externalDelivery?: boolean;
    taxRate?: string;
};

export type Payment = {
    subtotal: string;
    subtotalAfterDiscount: string;
    deliveryCost?: string;
    customerDeliveryCost?: string;
    restaurantDeliveryCost?: string;
    productDiscount?: string;
    tax?: string;
    taxRate?: string;
    deliveryCashHandlingFee?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    promotionsDiscount?: string;
    usedPromotions: Array<{
        promotionId: PromotionId;
        menuItemId: MenuItemId;
        cartItemKey?: string;
    }>;
    usedGifts?: Array<UsedGift>;
    usedGiftCredits?: string;
    usedNonGiftCredits?: string;
    usedLetsEatCredits?: string;
    usedCompanyCredits?: string;
    usedCredits?: string;
    serviceFee?: string;
    serviceFeeRate?: number;
    restaurantServiceFee?: string;
    restaurantServiceFeeRate?: number;
    total: string;
    orderItemPickupTimes: Array<PickupTime>;
    [key: PickupTime]: PaymentPerPickupTime;
};

export type PickupTime = string & { __Type__: 'PickupTime' };

export type PaymentPerPickupTime = {
    subtotal: string;
    subtotalAfterDiscount: string;
    productDiscount?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    usedGifts?: Array<UsedGift>;
    usedGiftCredits?: string;
    usedNonGiftCredits?: string;
    usedLetsEatCredits?: string;
    usedCompanyCredits?: string;
    usedCredits?: string;
    total: string;
};

export type UsedGift = {
    giftId: GiftId;
    giftEmitter: GiftEmitter;
    usedCredits: string;
    companyName?: string;
    promoCode?: string;
};
