import * as React from 'react';
import { CheckoutElement } from 'src/components/checkout/CheckoutElement';
import { translate } from 'src/i18n/translate';
import { ClockIcon } from 'src/icons/ClockIcon';
import { actions } from 'src/reducers';
import { isAsapPickupTime } from 'src/utils/order/isAsapPickupTime';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPlannedPickupTime } from 'src/utils/order/isPlannedPickupTime';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { formatPickupTime } from 'src/utils/transform/formatPickupTime';

export function EstimatedDeliverySection(): React.ReactElement {
    const driverArrivesAtStoreTime = useSelector((state) => state.app.driverArrivesAtStoreTime);
    const externalDeliveryEstimatedTime = useSelector((state) => state.app.restaurant?.externalDeliveryEstimatedTime);
    const orderType = useSelector((state) => state.app.orderType);
    const pickupTimeType = useSelector((state) => state.app.pickupTimeType);
    const pickupTime = useSelector((state) => state.app.pickupTime);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const preparationTime = useSelector((state) => state.app.restaurant?.preparationTime);
    const hideDeliveryEstimatedTimeEnabled = useSelector((state) => state.app.restaurant?.hideDeliveryEstimatedTimeEnabled);

    const openSelectPickupTimeDialog = useAction(actions.openSelectPickupTimeDialog);

    const isAsapDeliveryOrder = isDeliveryOrder(orderType) && isAsapPickupTime(pickupTimeType);
    const isPlannedDeliveryOrder = isDeliveryOrder(orderType) && isPlannedPickupTime(pickupTimeType);

    const getTitle = () => {
        if (isAsapDeliveryOrder) {
            if (hideDeliveryEstimatedTimeEnabled) return translate('Select delivery time');
            return translate('Estimated delivery');
        }
        if (isPlannedDeliveryOrder) {
            return translate('Program your order');
        }
        return translate('Program your order');
    };

    const getContent = () => {
        if (isAsapDeliveryOrder) {
            if (hideDeliveryEstimatedTimeEnabled) return translate('Order now');

            if (externalDeliveryEstimatedTime) {
                return `${translate(`${externalDeliveryEstimatedTime ?? 'DEFAULT'}`)} mins`;
            }
            return `${translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`)} mins`;
        }
        if (isPlannedDeliveryOrder) {
            return translatePickupTime();
        }
    };

    const translatePickupTime = () => {
        if (isAsapPickupTime(pickupTimeType)) {
            if (isDeliveryOrder(orderType)) {
                return translate('Estimated delivery @minutes min', { minutes: translate(`DriverArrivesAtStoreTimes.${driverArrivesAtStoreTime ?? 'DEFAULT'}`) });
            }
            return translate('Ready in @minutes min').replace('@minutes', `${preparationTime}-${preparationTime + 5}`);
        }
        if (isPlannedPickupTime(pickupTimeType)) {
            return formatPickupTime(pickupTime, timeZone);
        }
        return translate('Select time for pre-ordering');
    };

    return <CheckoutElement title={getTitle()} content={getContent()} icon={<ClockIcon color={'#C1C3C8'} />} action={openSelectPickupTimeDialog} actionTitle={translate('Select delivery time')} />;
}
