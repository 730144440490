import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createMexicanOrderInvoiceApi } from 'src/api/pidedirecto/invoice/createMexicanOrderInvoiceApi';
import type { OrderVm } from 'src/api/pidedirecto/types/OrderVm';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCardTypeSelect } from 'src/components/form/FormCardTypeSelect';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPaymentMethodSelect } from 'src/components/form/FormPaymentMethodSelect';
import { FormRegimeFiscalSelect } from 'src/components/form/FormRegimeFiscalSelect';
import { FormRfcField } from 'src/components/form/FormRfcField';
import { FormTextField } from 'src/components/form/FormTextField';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { InvoiceLoader } from 'src/scenes/invoicePage/InvoiceLoader';
import { AppTheme } from 'src/styles/AppTheme';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { upperCase } from 'src/utils/transform/upperCase';

export function MexicanInvoiceForm({ order }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const paymentMethod = useWatch<PaymentMethod>({ name: 'paymentMethod', control: form.control });

    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async (form: any) => {
        setLoading(true);
        setEmail(form.email);
        const response = await createMexicanOrderInvoiceApi({
            orderId: requireValue(order?.orderId),
            paymentMethod: requireValue(paymentMethod ?? order?.paymentMethod),
            rfc: upperCase(form.rfc),
            businessName: form.businessName,
            cardType: form.cardType,
            regimeFiscal: form.regimeFiscal,
            email: form.email,
            street: `${form.street} #${form.number} ${form.internalNumber ?? ''}, ${form.zipCode}, ${form.city} ${form.state}`,
            zipCode: form.zipCode,
        });
        setLoading(false);

        if (!response.ok) {
            setSuccess(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSuccess(true);
    };

    if (!order) return null;

    return (
        <div className={classes.container}>
            {!success && (
                <Form form={form} className={classes.form} onSubmit={handleSubmit}>
                    <p className={classes.title}>{translate('Enter your tax information')}</p>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <FormRfcField name='rfc' label={translate('RFC')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='businessName' label={translate('Business Name')} required />
                        </Grid>
                        {order.mexicanPaymentMethodCode && (
                            <Grid item xs={12}>
                                {isMexico(restaurantCountry) && (
                                    <Grid item xs={12} md={6}>
                                        <FormTextField
                                            name='mexicanPaymentMethodCode'
                                            label={translate('Payment Method')}
                                            disabled={true}
                                            defaultValue={translate(`MexicanPaymentMethodCodes.${order.mexicanPaymentMethodCode}`)}
                                        />
                                    </Grid>
                                )}
                                {!isMexico(restaurantCountry) && <FormPaymentMethodSelect name='paymentMethod' label={translate('Payment Method')} required />}
                            </Grid>
                        )}
                        {!order.mexicanPaymentMethodCode && (
                            <Grid item xs={12}>
                                <FormTextField name='paymentMethod' label={translate('Payment Method')} disabled={true} defaultValue={translate(order.paymentMethod)} />
                            </Grid>
                        )}
                        {!order.mexicanPaymentMethodCode && isCardPayment(order.paymentMethod) && (
                            <Grid item xs={12}>
                                <FormCardTypeSelect name='cardType' label={translate('Card Type')} required />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormRegimeFiscalSelect name='regimeFiscal' label={translate('Regime Fiscal')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='zipCode' label={translate('Zip Code')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='city' label={translate('City')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} required />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button classes={{ button: classes.button }} type='submit' disabled={isSubmitting}>
                                <div>{translate('Generate')}</div>
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
            {success && (
                <div className={classes.loaderContainer}>
                    <InvoiceLoader isSuccessful={success} loading={loading} isCompleted={!loading}>
                        <span className={classes.successfulText}>{translate('Successful billing')}</span>
                        <div className={classes.messageContainer}>
                            {translate('The invoice was sent to')} <span className={classes.textBold}>{email}</span>
                        </div>
                    </InvoiceLoader>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: 15,
        padding: '30px 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0,
        height: 'fit-content',
        position: 'relative',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 20,
        margin: 0,
        textAlign: 'center',
        fontFamily: AppTheme.typography.regular,
        color: '#0D3037',
    },
    form: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
    },
    buttonContainer: {
        width: '80%',
    },
    button: {
        width: '100%',
    },
    loaderContainer: {
        width: '100%',
        height: '80vh',
        display: 'flex',
    },
    successfulText: {
        fontWeight: 500,
        fontSize: '16px',
    },
    messageContainer: {
        width: '100%',
        color: '#818696',
        textAlign: 'center',
        fontFamily: AppTheme.typography.regular,
    },
    textBold: {
        fontWeight: 600,
    },
}));

type Props = {
    order?: OrderVm;
};
